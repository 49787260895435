<template>
<div v-if="perms.includes('Facturation Clients') || perms.includes('Admin')">
   <div v-if="loading || load">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <section class="invoice-add-wrapper" v-else>
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="9"
      >
        <b-form @submit.prevent id="formdata">
          <b-row>
          <b-col cols="12">
            <!-- <b-card class="invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <div>
                    <div class="logo-wrapper">
                      <h3 class="text-primary invoice-logo">
                        Facture Client
                      </h3>
                    </div>
                  </div>
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Facture N°
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="rows.NumFactClient"                        
                        />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        Date:
                      </h4>
                      <b-input-group class="mb-1" style="width: 50%;">
                        <b-form-input
                          id="example-input"
                          v-model="rows.created_at"
                          type="text"
                          autocomplete="off"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            format='dd/mm/yyyy'
                            v-model="rows.created_at"
                            button-only
                            button-variant="outline-primary"
                            right
                            size="sm"
                            locale="fr"
                            aria-controls="example-input"
                            
                          />
                        </b-input-group-append>
                        </b-input-group>
                    </div>
                  </div>
                </div>
            </b-card> -->
          </b-col>
          <b-col cols="12" md="8" style="max-height: 600px; overflow: auto;">
            <b-card v-for="(bl, index) in ListBL" :key="index" class="invoice-padding form-item-section">
                <div ref="form">
                  
                    <b-row
                      v-for="(item, index) in bl.Products"
                      :key="index"
                      ref="row"
                      class="pb-2"
                    >
                      <b-col cols="12">

                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <!-- Single Item Form Headers -->
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Produit
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Prix Vente
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              TVA
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Quantité
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              Total H.T
                            </b-col>
                             <b-col
                              cols="12"
                              lg="2"
                            >
                              Total T.T.C
                            </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                             <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Produit</label>
                              <b-form-input
                                type="text"
                                class="mb-2"
                                :value="item.Nom"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Prix Vente</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                :value="item.Prix_Vente"
                                disabled
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">TVA</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                disabled
                                :value="item.tva"
                              />
                            </b-col> 
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Quantité</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                v-model="item.qnt"
                                :name="'qnty'+index"
                                 disabled
                                
                                />
                               
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total H.T</label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                disabled
                                :value="item.tot"
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              lg="2"
                            >
                              <label class="d-inline d-lg-none">Total T.T.C </label>
                              <b-form-input
                                type="number"
                                class="mb-2"
                                disabled
                                :value="TwoDigits((item.Prix_Vente * item.qnt)*(1+(item.tva/100)))"
                              />
                            </b-col>
                           
                          </b-row>
                          <!-- <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                            <feather-icon
                              size="16"
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeItemProduct(rows.Products,index)"
                            />
                           
                          </div> -->
                        </div>
                      </b-col>
                    </b-row>
                </div><br/>
                 <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="success"
                    v-b-modal.modal_update_bl @click="showUpdate(bl)"
                    >
                    Modifier Bon 
                  </b-button>
                  <br/><br/>
                
                         
             </b-card>
          </b-col>
          <b-col cols="12" md="4" style="max-height: 600px; overflow: auto;">
            <b-card
              no-body
              class="invoice-preview-card"
              >
              <b-card-body class="invoice-padding pb-0">
                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                    <b-card-body class="pt-0">
                      <b-form-group label="Facture N°">
                        <b-form-input
                          id="invoice-data-id"
                          v-model="rows.NumFactClient"
                          @change="CheckNumFact(rows)"                       
                        />
                        <template v-if="errorFact">
                        <small class="text-danger">Ce Numéro est déjà existe</small>
                      </template>
                      </b-form-group>
                    </b-card-body>
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                    <b-card-body class="pt-0">
                      <b-form-group label="Date Facture">
                        <b-form-input
                          id="example-input"
                          v-model="rows.DateFact"
                          type="date"
                          autocomplete="off"
                        />
                      </b-form-group>
                    </b-card-body>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <b-card-body class="pt-0">
                        <!-- <span class="font-weight-bold">Total H.T: </span> -->
                        <b-form-group
                          style="margin-bottom: 2rem"
                          label="Reglement"
                        >
                          <v-select
                            style="
                              border: 1px solid #0000002b;
                              border-radius: 0.357rem;
                            "
                            v-model="reglement"
                            label="title"
                            :options="optionRoles"
                          />
                        </b-form-group>
                      </b-card-body>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                    <b-card-body class="pt-0">
                      <b-form-group label="Date Réglement">
                        <b-form-input
                          v-model="rows.dateReg"
                          type="date"
                          :disabled="
                            reglement.value == '' ||
                            reglement.length == 0 ||
                            reglement.value == 'Pas Encore'
                          "
                        />
                      </b-form-group>
                    </b-card-body>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <b-card-body class="pt-0">
                        <b-form-group label="Numéro Cheque">
                          <b-form-input
                            v-model="rows.NumCheq"
                            name="numcheq"
                            :disabled="
                              reglement.value == '' ||
                              reglement.value == 'Espèce' ||
                              reglement.length == 0 ||
                              reglement.value == 'Pas Encore' ||
                              reglement.value == 'Virement'
                            "
                          />
                        </b-form-group>
                      </b-card-body>
                    </b-col>

                    <b-col
                      cols="12"
                      
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <b-card-body class="pt-0">
                        <b-form-group label="BQ">
                          <b-form-input
                            v-model="rows.BQ"
                            name="bq"
                            :disabled="
                              reglement.value == '' ||
                              reglement.value == 'Espèce' ||
                              reglement.length == 0 ||
                              reglement.value == 'Pas Encore'||
                              reglement.value == 'Virement'
                            "
                          />
                        </b-form-group>
                      </b-card-body>
                    </b-col>

                    
                    <b-col
                      cols="12"
                      
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                    <b-card-body class="pt-0">
                      <b-form-group label="La banque">
                        <v-select
                          v-model="banque"
                          label="title"
                          :options="optionBanqs"
                          :disabled="
                            reglement.value == '' ||
                            reglement.value == 'Espèce' ||
                            reglement.length == 0 ||
                            reglement.value == 'Pas Encore'
                          "
                        />
                      </b-form-group>
                    </b-card-body>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <b-card-body class="pt-0">
                        <!-- <span class="font-weight-bold">Total H.T: </span> -->
                        <b-form-group style="margin-bottom: 2rem" label="Payée">
                          <b-form-checkbox
                            class="custom-control-info"
                            name="check-button"
                            switch
                            v-model="rows.paid"
                            value="1"
                            unchecked-value="0"
                          />
                        </b-form-group>
                      </b-card-body>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <b-card-body class="pt-0">
                        <!-- <span class="font-weight-bold">Total H.T: </span> -->
                        <b-form-group
                          style="margin-bottom: 2rem"
                          label="Fournis au client"
                        >
                          <b-form-checkbox
                            class="custom-control-info"
                            name="check-button"
                            switch
                            v-model="rows.livred"
                            value="1"
                            unchecked-value="0"
                          />
                        </b-form-group>
                      </b-card-body>
                    </b-col>
                    <b-col
                      cols="12"
                      
                      class="mt-md-0 mt-3 d-flex align-items-center"
                      order="2"
                      order-md="1" >
                      <b-card-body class="invoice-padding pt-0">
                          <span class="font-weight-bold">Note </span>
                          <b-form-textarea
                              v-model="rows.notefact"
                              placeholder="Note :"
                              rows="2"
                              max-rows="8"
                          />
                      </b-card-body>
                  </b-col>
                  </b-row>
                  <b-row>
              
                  <!-- <b-col
                    cols="12"
                    
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                  <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Remise  : </span>
                  <b-form-input
                      disable        
                      type="number"
                      placeholder="5%"
                      class="mb-2" />
                      
                  </b-card-body>
                  </b-col> -->
                  
                  <b-col
                    cols="12"
                    
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2">
                
                    <div class="invoice-total-wrapper" style="max-width: 100%;">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total H.T:
                        </p>
                        <p class="invoice-total-amount">
                        {{TwoDigits(rows.THT)}} DHs
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total TVA:
                        </p>
                        <p class="invoice-total-amount">
                        {{TwoDigits(rows.TTV)}} 
                        </p>
                      </div>
                      <!--  -->
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total T.T.C:
                        </p>
                        <p class="invoice-total-amount">
                        {{TwoDigits(rows.TTC)}} DHs
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Remise:
                        </p>
                        <p class="invoice-total-amount">
                          {{rows.remise}} %
                        </p>
                      </div>
                    
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total NET A PAYER:
                        </p>
                        <p class="invoice-total-amount">
                        {{TwoDigits(rows.TTC*(1-(rows.remise/100)))}}
                        </p>
                      </div>
                    </div>
                  </b-col>
                  </b-row>
                  

              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">
              <b-col
                cols="12"
                md="12"
                xl="12"
                class="invoice-actions mt-md-0 mt-2" >

                <b-card>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mb-75"
                    block
                    @click="EditFacture()"
                    
                  >
                    Modifier La facture 
                  </b-button>
              
                </b-card>

              
              </b-col>
        
              
            </b-card>
          </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </section>
    <b-modal
      id="modal_update_bl"
      title="Modifier Bon Livraison"
      cancel-variant="outline-secondary"
      size="xl"
      hide-footer

    >
       <EditBonLiv :getRecord="getRecord" :rowBL="rowBL" :factID="FactID" />
       
    </b-modal>
  </div>

  <div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import axios from 'axios'
import moment from 'moment';
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, 
  BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,BTableLite,
  BMedia, BMediaAside, BMediaBody, BLink,BImg,BFormDatepicker, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import EditBonLiv from '@/views/admin/Clients/Factures/EditBonLiv.vue';
import NotAuthoriz from '@/views/admin/NotAuthoriz.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,BTableLite,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BMedia, BMediaAside, BMediaBody, BLink,BImg,
    flatPickr,
    vSelect,
    Logo,
    NotAuthoriz,
    BFormDatepicker, BInputGroupAppend,
    EditBonLiv
    // InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data(){
      return{
        perms:[],
        items:[],
          loading:false,
          clients:[],
          Produits:[],
          rows:{},
          ListBL:[],
          NumDevis:'',
          totalHT:0,
          totalNET:0,
          tva:0,
          DateDevis:'',
          remise:0,
          TTC:0,
          THT:0,
          TTV:0,
          prod:[],
          newProdc:[],
          optionRoles: [
            { title: "Pas Encore", value: "Pas Encore" },
            { title: "Espèce", value: "Espèce" },
            { title: "Chèque", value: "Chèque" },
            { title: "Virement", value: "Virement" },
            { title: "Effet", value: "Effet" },
          ],
          PaidLivred: [{ title: 'Oui' ,value: 1}, { title: 'Non' ,value: 0 }],
          paid:0,
          livred:0,
          UserID:{},
          error:false,
          reglement:{title: '',value:''},
          banque:{ title: '', value:'' },
          optionBanqs: [
            { title: "BMCI", value: "BMCI" },
            { title: "CIH", value: "CIH" },
            { title: "Banque Populaire", value: "Banque Populaire" },
            { title: "BMCE", value: "BMCE" },
            // { title: "Al Barid Bank", value: "Al Barid Bank" },
          ],

          rowBL:{},
          FactID:router.currentRoute.params.id,
          errorFact:false,



      }
  },
  mounted() {
  //  
    this.perms=JSON.parse(localStorage.getItem('access'));
    this.getRecord(router.currentRoute.params.id);
    


      

    
    // this.$http.get(`${config.API_BASE_URL}/api/auth/getProductDevis/${router.currentRoute.params.id}`).then(res => { 
    //   this.prod = res.data,
    //   this.TTC=this.prod.TotalNet*(1-this.prod.remise/100),
    //   this.THT=this.prod.TotalHT,
    //   this.TTV=this.prod.TVA
      
    //   });
    this.UserID=JSON.parse(localStorage.getItem('userData'))
  },



  methods: {
      TwoDigits(val){
            return parseFloat(val).toFixed(2);
      },

      CalcTTC(item1,item2){
          let result=[];
          result=item1.concat(item2);
        this.TTC=0;this.THT=0; this.TTV=0;
        for (let i = 0; i < result.length; i++) {
           this.TTC+=parseFloat((result[i].Prix_Vente*result[i].qnt)*(1+(result[i].tva/100)));
           this.THT+=parseFloat((result[i].Prix_Vente*result[i].qnt))
            this.TTV+=parseFloat((result[i].Prix_Vente*result[i].qnt)*(result[i].tva/100))
            }
        //   console.log(this.TTC)
      },
      
    showUpdate(bl){
      this.rowBL=bl.id_devis;
    },

    EditFacture(){
      if(this.errorFact){
        this.$swal.fire({
              icon: 'error',
              title: 'Numéro déjà existe',
              text: 'Le numéro de la facture est déjà existe' 
        })
      }else if(!this.rows.NumFactClient){
        this.$swal.fire({
              icon: 'error',
              title: 'Numéro Facture',
              text: 'Le numéro de la facture est obligatoire' 
        })
      }
      else{
          let data2 = new FormData;
          data2.append('UserID', this.UserID.id);
          data2.append('DateFact', this.rows.DateFact);
          data2.append('reglement', this.reglement.value);
          data2.append('NumFact', this.rows.NumFactClient);
          data2.append('paid', this.rows.paid);
          data2.append('livred', this.rows.livred);
          data2.append('BQ', this.rows.BQ);
          data2.append('NumCheq', this.rows.NumCheq);
          data2.append('banque', this.banque.value);
          data2.append('dateReg', this.rows.dateReg);
          if(this.rows.notefact==null){
            this.rows.notefact='';
            data2.append('notefact', this.rows.notefact);
          }else{
            data2.append('notefact', this.rows.notefact);
          }
          console.log(Object.fromEntries(data2))
          this.$http.post(`${config.API_BASE_URL}/api/auth/EditFactClient/${router.currentRoute.params.id}`,data2)
          .then(response =>{
              if(response.data=='success'){
              this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre facture est bien modifiée',
                  showConfirmButton: false,
                  timer: 1000 })
                  this.getRecord(router.currentRoute.params.id)
                  // this.$router.push('/FactureClient');
              }else{
                this.$swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Something went wrong! Pleaz try again'
                  }) }
          })
      }
        
    
    },
    getRecord(factId){
      this.$http.get(`${config.API_BASE_URL}/api/auth/getProductFact/${factId}`).then(res => { 
      this.rows = res.data.Fact,
      this.ListBL=res.data.BLS,
      this.reglement.value=res.data.Fact.reglement,
      this.reglement.title=res.data.Fact.reglement,
      this.banque.title=res.data.Fact.banque,
      this.banque.value=res.data.Fact.banque
    
    }).finally(() => {
      this.loading = false
    });
    },

    CheckNumFact(rows){
      this.$http
        .post(`${config.API_BASE_URL}/api/auth/CheckNumFact?numFact=${rows.NumFact}?id=${rows.id}`)
        .then((res) => {
            if(res.data=='exist'){
              this.errorFact=true;
            }else if(res.data=='success'){
              this.errorFact=false;
            }
        });
      // console.log(rows)
    },

    // fetchItems() {
    //   this.$store.dispatch('produits/fetchProductFact', { factId: router.currentRoute.params.id })
    //     .then(response => {
    //       console.log(response.data)
    //       this.rows = response.data
    //     })
    // },
    
   
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
    const load=ref(false)
    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })


    const itemFormBlankItem = {
      id:0,
      Nom: null,
      Prix_Vente: 0,
      qnt: 0,
      tva:0,
      tot:0,
    }
   const PrixProd= ref({
        Prices :[],
    })
    const FactureData= ref({
        produits :[JSON.parse(JSON.stringify(itemFormBlankItem))],
    })
    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    })

    
     const updateItemForm = (index, val,clientID) => {
      const { Prix_Vente, id, Nom ,tva} = val
      load.value=true
       const apiClient= axios.get(`${config.API_BASE_URL}/api/auth/GetPrice/${clientID}/${id}`).then(function (response) {
      //  console.log(response.data.prix)
      load.value=false
        if(!PrixProd.value.Prices.find(o => o.id === response.data.id && o.val === response.data.val))
        {PrixProd.value.Prices.push(response.data)}
      FactureData.value.produits[index].Prix_Vente = Prix_Vente
      FactureData.value.produits[index].id = id
      FactureData.value.produits[index].Nom = Nom
      FactureData.value.produits[index].tva = tva
     })
    
    }
   

    // console.log(this.rows.Products)

    return {
      FactureData,
      invoiceData,
    //   clients,
      updateItemForm,PrixProd,
      itemFormBlankItem,load
    }
  },
}
</script>


<style scoped>
.media-aside.align-self-start {
    display: grid;
}
.invoice-add .invoice-total-wrapper[data-v-cc5561c2] {
    width: 100%;
    max-width: 18rem;
}

</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
